@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Noto+Serif+Bengali:wght@300;400&display=swap');

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fdf2f8;
}

 .bangla-font{
font-family: 'Noto Serif Bengali', serif !important;
 }
.orderContainer::-webkit-scrollbar,
body::-webkit-scrollbar {
  display: none;
}

@media (max-width: 480px) {
  .header {
    height: 300px;
  }
}

/* @media (max-width: 768px) {
  .header {
    height: 450px;
    
  }
} */

.header-bg {
  background-color: #f8fcfc !important;
}

.b:hover {
  color: white;
  background-color: #22a1b9;
}

.tab-bg {
  background-color: #800080;
}

.tab-text {
  color: #800080;
}

.poppins {
  font-family: "Poppins", sans-serif;
}

.bg-gradient-to-r from-cyan-500 via-purple-500 to-pink-500 {
  background: #22a1b9 !important;
}

.bg-secondery {
  background: #7b6ebe !important;
}

.bg-medium {
  background: #edecf0 !important;
}

.bg-second {
  background: #f5f7f8;
}

.bg-bkash {
  background-color: #e3146b;
}

.bg-bkash-2 {
  background-color: #c12354;
}

/* Glassmorphism card effect */
.glass {
  backdrop-filter: blur(14px) saturate(180%);
  -webkit-backdrop-filter: blur(14px) saturate(180%);
  background-color: rgba(255, 255, 255, 0.57);
  border-radius: 12px;
  border: 1px solid rgba(209, 213, 219, 0.3);
}

.pagination {
  margin: 30px;
}

.pagination button {
  padding: 0 10px;
  margin-right: 20px;
  color: black;
  float: left;
  padding: 8px 16px;
  text-decoration: none;
  transition: background-color 0.3s;
  border: 1px solid #ddd;
}

.pagination .selected {
  background-color: #22a1b9;
  color: white;
  border: 1px solid #4caf50;
}

/* skill card*/
.checkbox:checked {
  border: none;
}

.checkbox:checked + .check-icon {
  display: flex;
}

html {
  scroll-behavior: smooth;
}
